/*****************************************************************************
 * UmanIT - Calculates price for each suggested product
 *****************************************************************************/

const suggestedProducts = Array.from(document.querySelectorAll("article.product-quick-add"));
const stockTags = document.querySelectorAll("[data-stock='0'] .form-check");

stockTags.forEach(function (stockTag) {
  // largeur - border radius
  let a = stockTag.offsetWidth;
  let b = stockTag.offsetHeight;
  // longeur du trait
  let x = Math.sqrt(Math.pow(a, 2) + Math.pow(b, 2));

  // calcul de l'angle
  let angle = Math.atan(b / a);
  angle = angle * 180 / Math.PI

  // ajout de l'élément à la volée dans le DOM
  let strikedStock = document.createElement("span");
  strikedStock.classList.add("strikedStock");
  strikedStock.style.width = `${x}px`;
  strikedStock.style.transform = `rotate(-${angle}deg)`;
  stockTag.insertBefore(strikedStock, stockTag.firstChild);
});


suggestedProducts.forEach(function (suggestedProduct) {
  let price;
  let pricePerKg;
  let priceBase;

  const priceField = suggestedProduct.querySelector("[data-type='suggested-product-price-container']");
  const pricePerKgField = suggestedProduct.querySelector("[data-type='suggested-product-priceperkg-container']");
  const priceBaseField = suggestedProduct.querySelector("[data-type='base-price-container']");

  const productTagNew = suggestedProduct.querySelector(".product__tag--new");
  const productTagPromo = suggestedProduct.querySelector(".product__tag--promo");

  const variants = suggestedProduct.querySelectorAll("[data-type='suggested-product-variant']");
  const Listvariants = suggestedProduct.querySelectorAll(".js-list-variants");

  if (variants.length > 0) {

    Listvariants.forEach(Listvariant => {
      let inputs = Listvariant.querySelectorAll('input');
      inputs.forEach(input => {
        if (input.checked) {
          input.closest("[data-type='suggested-product-variant']").classList.add('active');
        }
      });
    });

    variants.forEach(function (variant) {
      getPrices(variant);
      getTag(variant, productTagNew, productTagPromo);

      variant.addEventListener("click", function () {
        variants.forEach(variant => {
          variant.classList.remove('active')
        });
        variant.classList.add('active');
        getPrices(variant);
        getImages(variant);
        getStock(variant);
        getTag(variant, productTagNew, productTagPromo);
      });
    });
  }

  /**
   * Gère les stocks en fonction de la variante
   *
   * @param variant
   */
  function getStock(variant) {
    let product = variant.closest("[data-role='product']");
    let stock = product.querySelector("[data-role='out-of-stock']");
    let staffDiscount = product.querySelector("[data-role='staff-discount']");
    const isWebChannel = document.querySelector("[data-channel='FR_WEB']");

    if (staffDiscount) {
      if (variant.dataset.staffdiscount) {
        staffDiscount.classList.add("d-block");
        staffDiscount.classList.remove("d-none");
      } else {
        staffDiscount.classList.add("d-none");
        staffDiscount.classList.remove("d-block");
      }
    }

    // Modification temporaire pour interdire la commande de produits en canal web
    if (isWebChannel) {
      stock.classList.add("d-block");
      stock.classList.remove("d-none");
      product.querySelector("[data-role='product-link']").setAttribute("disabled", "true");
    } else if (variant.dataset.stock) {
      if (0 == variant.dataset.stock) {
        stock.classList.add("d-block");
        stock.classList.remove("d-none");
        product.querySelector("[data-role='product-link']").setAttribute("disabled", "true");
      } else {
        stock.classList.add("d-none");
        stock.classList.remove("d-block");
        product.querySelector("[data-role='product-link']").removeAttribute("disabled");
      }
    } else {
      stock.classList.add("d-none");
      stock.classList.remove("d-block");
      product.querySelector("[data-role='product-link']").removeAttribute("disabled");
    }
  }

  /**
   * Récupère l'image en fonction de la variante renseignée
   *
   * @param variant
   */
  function getImages(variant) {

    let imageContainer = suggestedProduct.querySelector("[data-role='image-container']");
    let imageSelected = suggestedProduct.querySelector(`[data-role='image-product-url'][data-variants='${variant.dataset.variants}']`);

    // si pas de variante de renseignée pour l'image alors on récupère juste l'image renseignée
    if (!imageSelected) {
      imageSelected = suggestedProduct.querySelector(`[data-role='image-product-url']`);
    }

    if (imageContainer) {
      imageContainer.setAttribute('src', imageSelected.dataset.url);
      imageContainer.setAttribute('srcset', imageSelected.dataset.srcset);
    }
  }

  /**
   * Récupère les prix renseignés pour un produit
   *
   * @param variant
   */
  function getPrices(variant) {
    const inputsRadio = variant.querySelectorAll("input[type='radio']");

    inputsRadio.forEach(inputRadio => {
      if (inputRadio.checked) {
        // Prix du produit sélectionné
        if (variant.dataset.suggestedproductprice) {
          price = variant.dataset.suggestedproductprice;
        }

        // Prix au kilo du produit sélectionné
        if (variant.dataset.suggestedproductpriceperkg) {
          pricePerKg = variant.dataset.suggestedproductpriceperkg;

        }

        // Prix de base
        if (variant.dataset.suggestedproductpricebase) {
          priceBase = variant.dataset.suggestedproductpricebase;
        } else {
          priceBase = false;
        }

        getImages(variant);
        getStock(variant);
      }

      displayPrice(price, pricePerKg, priceBase);
    });
  }

  /**
   * Récupère les tags de promotions
   *
   * @param variant
   */
  function getTag(variant, productTagNew, productTagPromo) {
    let labelPromo = variant.getAttribute('data-promotiontagcontent');
    let colorPromo = variant.getAttribute('data-promotiontagcolor');

    if (variant.classList.contains('active')) {
      if (labelPromo && colorPromo) {
        if (productTagNew) {
          productTagNew.classList.add('d-none');
        }
        if(productTagPromo) {
        productTagPromo.classList.remove('d-none');
        productTagPromo.innerHTML = labelPromo;
        productTagPromo.style.borderColor = colorPromo;
        productTagPromo.style.color = colorPromo;
      }
      }

      else {
        if (productTagNew) {
          productTagNew.classList.remove('d-none');
          if (productTagPromo) {
            productTagPromo.classList.add('d-none');
          }
        }

        else if(productTagPromo) {
          productTagPromo.classList.add('d-none');
        }
      }
    }
  }

  /**
   * Met à jour les champs des prix (total selon la quantité) et et prix au kilo
   *
   * @param price
   * @param pricePerKg
   */
  function displayPrice(price, pricePerKg, priceBase) {
    if (price) {
      priceField.innerHTML = `${price}`;
    } else {
      priceField.innerHTML = "Prix non renseigné";
    }
    if (pricePerKg) {
      pricePerKgField.innerHTML = `(${pricePerKg} € / kg)`;
    } else {
      pricePerKgField.innerHTML = "(Prix au kg non renseigné)";
    }

    // utilisé que dans le cadre d'une promo
    if (priceBase) {
      priceBaseField.innerHTML = `${priceBase}`;
      priceBaseField.classList.add("d-inline-flex");
      priceField.classList.add("product__price--promo");
    } else {
      priceBaseField.classList.remove("d-inline-flex");
      priceField.classList.remove("product__price--promo");
    }
  }
});

